@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700&display=swap');

@layer base {
  html {
    font-family: 'Poppins', system-ui, sans-serif;
    scroll-behavior: smooth;
  }

  h1, h2, h3, h4, h5, h6 {
    @apply font-bold tracking-tight;
  }
}

@layer components {
  .btn-primary {
    @apply bg-gradient-to-r from-blue-600 to-blue-500 text-white py-3 px-8 rounded-full font-semibold 
           hover:from-blue-500 hover:to-blue-400 transition-all duration-300 
           shadow-lg hover:shadow-xl transform hover:-translate-y-1;
  }

  .card {
    @apply bg-white rounded-lg shadow-lg hover:shadow-xl transition-all duration-300 
           transform hover:-translate-y-1;
  }

  .section-title {
    @apply text-4xl font-bold text-center mb-16 bg-gradient-to-r from-blue-600 to-blue-400 
           bg-clip-text text-transparent;
  }
}

@layer utilities {
  .animate-fade-in {
    animation: fadeIn 0.5s ease-in forwards;
  }

  .animate-fade-in-up {
    animation: fadeInUp 0.7s ease-out forwards;
  }

  .animate-slide-in-right {
    animation: slideInRight 0.5s ease-out forwards;
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes slideInRight {
  from {
    opacity: 0;
    transform: translateX(20px);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}

/* Custom scrollbar */
::-webkit-scrollbar {
  width: 8px;
}

::-webkit-scrollbar-track {
  background: #f1f5f9;
}

::-webkit-scrollbar-thumb {
  background: #93c5fd;
  border-radius: 4px;
}

::-webkit-scrollbar-thumb:hover {
  background: #60a5fa;
}

/* Smooth transitions */
* {
  @apply transition-colors duration-200;
}

/* Glass effect for cards */
.glass-effect {
  @apply bg-white/90 backdrop-blur-md border border-white/20;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
